import React from "react";

const Downloads = () => {
  return (
    <div>
      <div className="backgroundBodyThree py-16">
        <div className=" px-8 py-8 bg-white text-[#074260]">
          <h5 className="text-sm">Contacts</h5>
          <h1 className="font-light text-3xl uppercase ">
            Get In <span className="font-bold">Touch</span>
          </h1>
          <h5 className="text-sm">
            Let’s Connect! See Below for Our Phone Number, Address & Other
            Contact Info
          </h5>
        </div>
      </div>
    </div>
  );
};

export default Downloads;
